<template>
  <div class="flights">flights here</div>
</template>

<script>
import axios from "axios";

export default {
  name: "Flights",
  data() {
    return {
      flights: [],
    };
  },
  mounted() {
    const options = {
      method: "GET",
      url: "https://api.aviationstack.com/v1/flights",
      params: {
        access_key: "8f69c096951d8620f1fc21ebd246da84",
        dep_iata: "LJU",
      },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  },
  methods: {},
};
</script>
  
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>